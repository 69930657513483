<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ $t('AppBondenWarehouse.edit.editBondenwarehouse') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Clave field -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- Clave Input -->
            <b-form-group
              label="Clave"
            >
              <b-form-input
                v-model="BondenWarehouse.clave"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- name Field -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppBondenWarehouse.columns.name')"
            >
              <b-form-input
                v-model="BondenWarehouse.texto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Alias Field -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppBondenWarehouse.columns.nickname')"
            >
              <b-form-input
                v-model="BondenWarehouse.alias"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
            </b-form-group>
          </b-col>
          <!-- Aduana -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppBondenWarehouse.columns.custom')"
            >
              <b-form-input
                v-model="BondenWarehouse.aduanaId"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Habilitado -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              :label="$t('AppBondenWarehouse.columns.Enabled')"
            >
              <b-form-checkbox
                v-model="BondenWarehouse.habilitado"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import generalDataService from '@/services/generalData.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    ValidationObserver,
  },
  setup() {
    /* SERVICES */
    const {
      fetchBondenWarehouse,
      updateBondenWarehouse,
    } = generalDataService()

    // Data
    const BondenWarehouse = ref({})
    fetchBondenWarehouse(router.currentRoute.params, data => {
      BondenWarehouse.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateBondenWarehouse(BondenWarehouse.value)
        .then(() => {
          router.push({ name: 'apps-bonden-warehouse-list' })
        })
    }
    /* Validations */
    const BondenWarehouseData = ref(JSON.parse(JSON.stringify(BondenWarehouse.value)))
    const resetAduanaData = () => {
      BondenWarehouseData.value = JSON.parse(JSON.stringify(BondenWarehouse))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetAduanaData)
    return {
      // Data
      BondenWarehouse,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
